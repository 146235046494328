import { fileParser } from "@senciamatch/frontend/libs/csv";
import { staffShiftTableParser } from "@senciamatch/frontend/libs/csvParser/staffsShiftTable";
import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { StaffShiftTable } from "@senciamatch/shared/models/staffShiftTable";
import { createWorkShiftGroup } from "@senciamatch/shared/models/workShiftGroup";
import type React from "react";
import { type DragEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v7 as uuidv7 } from "uuid";

interface Props {
  buildingShiftGroupId: string | null;
}

export const MatchingStaffs: React.FC<Props> = ({ buildingShiftGroupId }) => {
  const navigate = useNavigate();
  const [staffShiftTable, setStaffShiftTable] = useState<StaffShiftTable>();
  const createStaffShiftGroupMutation = trpc.createStaffShiftGroup.useMutation();
  const createWorkShiftGroupMutation = trpc.createWorkShiftGroup.useMutation();

  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback(async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file) return;
    try {
      const data = await fileParser(file);

      if (!Array.isArray(data)) return;

      const parsedStaffs = await staffShiftTableParser.parse(data);

      setStaffShiftTable(parsedStaffs);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!staffShiftTable || !buildingShiftGroupId) return;

    const params = {
      staffShiftGroup: staffShiftTable.staffShiftGroup,
      staffShifts: staffShiftTable.rows.flatMap((row) => row.cells).filter((cell) => cell !== null),
    };

    await createStaffShiftGroupMutation.mutateAsync(params);

    const year = Number.parseInt(staffShiftTable.staffShiftGroup.month.substring(0, 4), 10);
    const month = Number.parseInt(staffShiftTable.staffShiftGroup.month.substring(4, 6), 10) - 1;
    const date = new Date(year, month);

    const workShiftGroup = createWorkShiftGroup({
      staffShiftGroupId: staffShiftTable.staffShiftGroup.id,
      buildingShiftGroupId,
      name: staffShiftTable.staffShiftGroup.month,
      month: date,
    });

    await createWorkShiftGroupMutation.mutateAsync({ ...workShiftGroup });

    navigate(`/matchings/${workShiftGroup.id}`);
  }, [
    staffShiftTable,
    createStaffShiftGroupMutation,
    buildingShiftGroupId,
    navigate,
    createWorkShiftGroupMutation.mutateAsync,
  ]);

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">スタッフのシフト希望登録</h2>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="rounded-md border-dashed border-2 border-gray-200 bg-white p-4 min-h-40 flex flex-col justify-center items-center mb-10"
      >
        <p className="text-center">ファイルをここにドラッグ＆ドロップしてください</p>
      </div>

      {staffShiftTable?.rows && (
        <div className="flex flex-col h-full">
          <div className="flex-grow overflow-hidden border bg-white border-base-100  flex flex-col">
            <div className="flex items-center justify-between">
              <p className="text-xl font-bold ml-8 mt-8">スタッフのシフト情報</p>
              <button
                type="button"
                className="bg-primary-700 text-white px-4 py-2 rounded-md mt-8 mx-8"
                onClick={() => {
                  handleSubmit();
                  navigate("/matchings/01919abf-c98e-756e-87a2-b2fe0c48b906");
                }}
              >
                新規登録
              </button>
            </div>

            <p className="text-red-500 mx-8">1件の入力エラーがあります。</p>

            <div className="h-full overflow-auto ml-8 mt-8">
              <table className="w-[2450px] border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th className="sticky top-0 left-0 bg-gray-100 p-2 border border-gray-100 z-20 min-w-[300px]">
                      スタッフ
                    </th>
                    {Array.from({ length: staffShiftTable.rows[0].cells.length }, (_, i) => {
                      const dateStr = `${staffShiftTable.staffShiftGroup.month}${i < 10 ? `0${i + 1}` : i + 1}`;
                      console.log(dateStr);
                      const dayOfWeek = getJapaneseDayOfWeek(dateStr);
                      const bgColor =
                        dayOfWeek === "日" ? "bg-red-100" : dayOfWeek === "土" ? "bg-blue-100" : "bg-gray-100";
                      return (
                        <th key={uuidv7()} className={`sticky top-0 ${bgColor} p-2 border border-gray-100 z-10`}>
                          {i + 1}
                          <br />
                          {dayOfWeek}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {staffShiftTable.rows.map((row) => {
                    const hasError = row.cells.some(
                      (s) => s != null && !staffShiftTableParser.VALID_SHIFT.includes(s.shiftSymbol),
                    );
                    return (
                      <tr key={uuidv7()}>
                        <td
                          className={`sticky left-0 p-2 bg-white border border-gray-100 z-10 ${hasError ? "bg-red-300" : ""}`}
                        >
                          {row.label}
                        </td>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={uuidv7()}
                              className={`border border-gray-100 w-[270px] h-[30px] bg-white text-center ${
                                cell != null && staffShiftTableParser.VALID_SHIFT.includes(cell.shiftSymbol)
                                  ? ""
                                  : "bg-red-300"
                              }`}
                            >
                              {cell != null ? <span>{cell.shiftSymbol}</span> : null}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import { z } from "zod";

export const companySchema = z.object({
  id: z.string().brand("companyId"),
  name: z.string(),
});

export const newCompanyId = (id: number) => companySchema.shape.id.parse(id);

export type Company = z.infer<typeof companySchema>;

export type CompanyInput = z.input<typeof companySchema>;

export const newCompany = (input: CompanyInput): Company => companySchema.parse(input);

export const createCompany = (input: CompanyInput): Company => newCompany(input);

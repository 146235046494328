import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type Sidebars = "home" | "add-master" | "staffs" | "buildings" | "matching" | "history" | null;

interface SidebarMenuState {
  sidebarIsOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useSidebarMenuStore = create<SidebarMenuState>()(
  devtools(
    persist(
      (set) => ({
        sidebarIsOpen: false,
        setIsOpen: (isOpen: boolean) => set(() => ({ sidebarIsOpen: isOpen })),
      }),
      { name: "sm-sidebarStateStore" },
    ),
  ),
);

import { z } from "zod";
import { staffShiftSchema } from "./staffShift";
import { staffShiftGroupSchema } from "./staffShiftGroup";

export const staffShiftTableSchema = z.object({
  staffShiftGroup: staffShiftGroupSchema,
  rows: z.array(
    z.object({
      label: z.string(),
      cells: z.array(staffShiftSchema.or(z.null())),
    }),
  ),
});

export type StaffShiftTable = z.infer<typeof staffShiftTableSchema>;

export type StaffShiftTableInput = z.input<typeof staffShiftTableSchema>;

export const newStaffShiftTable = (input: StaffShiftTableInput): StaffShiftTable => staffShiftTableSchema.parse(input);

export const createStaffShiftTable = (input: StaffShiftTableInput): StaffShiftTable => newStaffShiftTable(input);

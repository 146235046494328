import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { Link, useNavigate } from "react-router-dom";

export const UsersPage: React.FC = () => {
  const getUsers = trpc.getUsers.useQuery();
  const navigate = useNavigate();

  if (getUsers.isLoading || !getUsers.data) return <LoadingData />;
  if (!getUsers.data) return <ElementNotFound />;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">ユーザー一覧</h2>
      <div className="overflow-x-auto py-10 bg-white border border-base-100 px-8 flex-grow flex flex-col">
        <div className="flex justify-between pb-8 flex-shrink-0">
          {/* <input type="text" className="w-1/2 border border-gray-200 rounded-md px-4 py-2" placeholder="検索" /> */}
          <button
            type="button"
            className="bg-primary-700 text-white px-4 py-2 rounded-md"
            onClick={() => navigate("/users/new")}
          >
            新規登録
          </button>
        </div>
        <div className="overflow-y-auto flex-grow">
          <table className="min-w-full">
            <thead className="sticky top-0 bg-gray-100">
              <tr className="bg-gray-100">
                <th className="p-2 text-left border border-gray-200">ID</th>
                <th className="p-2 text-left border border-gray-200">名前</th>
                <th className="p-2 text-left border border-gray-200">メールアドレス</th>
                <th className="p-2 text-left border border-gray-200">作成日</th>
                <th className="p-2 text-left border border-gray-200">有効</th>
                <th className="p-2 text-left border border-gray-200" />
              </tr>
            </thead>
            <tbody>
              {(getUsers.data?.users || []).map((user) => (
                <tr key={user.id}>
                  <td className="p-2 text-left border border-gray-200">{user.id}</td>
                  <td className="p-2 text-left border border-gray-200">
                    {user.lastName} {user.firstName}
                  </td>
                  <td className="p-2 text-left border border-gray-200">{user.email}</td>
                  <td className="p-2 text-left border border-gray-200">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="p-2 text-left border border-gray-200">{user.isActive ? "有効" : "無効"}</td>
                  <td className="p-2 text-left border border-gray-200">
                    <Link to={`/users/${user.id}/edit`} className="text-primary-700">
                      編集
                    </Link>
                    <span className="mx-2 text-gray-500">|</span>
                    <Link to={`/users/${user.id}/change-password`} className="text-primary-700">
                      パスワード変更
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { buildingShiftGroupSchema } from "./buildingShiftGroup";
import { buildingSchema } from "./building";

export const buildingShiftSchema = z.object({
  id: z.string().brand("buildingShiftId"),
  buildingShiftGroupId: buildingShiftGroupSchema.shape.id,
  buildingId: buildingSchema.shape.id,
  startAt: z.date(),
  endAt: z.date(),
});

export type BuildingShift = z.infer<typeof buildingShiftSchema>;

export type BuildingShiftInput = z.input<typeof buildingShiftSchema>;

export const newBuildingShift = (input: BuildingShiftInput): BuildingShift => buildingShiftSchema.parse(input);

export const createBuildingShift = (input: Omit<BuildingShiftInput, "id">): BuildingShift =>
  newBuildingShift({ ...input, id: uuidv7() });

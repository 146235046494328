import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { LoadingSpinner } from "@senciamatch/frontend/components/LoadingSpinner";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GroupByStaff } from "./components/groupByStaff";
import { GroupByBuilding } from "./components/groupByBuilding";
export const MatchingShowPage: React.FC = () => {
  const { id } = useParams();
  const [mode, setMode] = useState<"edit" | "view">("view");
  const [displayMode, setDisplayMode] = useState<"staff" | "building">("staff");
  const [selectedWorkShiftIds, setSelectedWorkShiftIds] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();

  const getWorkShiftGroup = trpc.getWorkShiftGroup.useQuery({ id: id ?? "" });

  if (getWorkShiftGroup.isLoading) return <LoadingData />;
  if (!getWorkShiftGroup.data) return <ElementNotFound />;

  const workShiftGroup = getWorkShiftGroup.data;

  if (["created", "matching"].includes(workShiftGroup.status))
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center bg-white p-8 rounded-md">
          <LoadingSpinner />
          <p className="text-xl font-bold mt-4">現在シフトの作成中です</p>
        </div>
      </div>
    );

  if (workShiftGroup.status === "cancelled")
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center bg-white p-8 rounded-md">
          <p className="text-xl font-bold mt-4">シフトの作成に失敗しました</p>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">{workShiftGroup.name}のシフト一覧</h2>
      <div className="flex-grow overflow-hidden border bg-white border-base-100 flex flex-col">
        <div className="bg-gray-100 h-20 flex items-center gap-4 px-8">
          {mode === "edit" ? (
            <>
              <button
                type="button"
                className={`bg-[#00AF31] text-white px-4 py-2 rounded-md w-40 ${
                  Object.values(selectedWorkShiftIds).every((v) => v) ? "opacity-50" : ""
                }`}
                onClick={() => {
                  if (Object.values(selectedWorkShiftIds).every((v) => v)) return;
                  navigate(
                    `/work-shifts/edit?ids=${Object.entries(selectedWorkShiftIds)
                      .filter(([_, v]) => v)
                      .map(([k]) => k)
                      .join(",")}`,
                  );
                }}
              >
                選択項目を修正
              </button>
              <button
                type="button"
                className="bg-black text-white px-4 py-2 rounded-md w-40"
                onClick={() => {
                  setMode("view");
                }}
              >
                手動修正終了
              </button>
            </>
          ) : (
            <>
              <label className="text-sm flex items-center gap-2">
                <input
                  type="radio"
                  className="hidden"
                  checked={displayMode === "staff"}
                  onChange={() => {
                    setDisplayMode("staff");
                  }}
                />
                {displayMode === "staff" ? (
                  <img src="/icons/radio_button_checked.svg" alt="checked" />
                ) : (
                  <img src="/icons/radio_button_unchecked.svg" alt="unchecked" />
                )}
                従業員別
              </label>
              <label className="text-sm flex items-center gap-2">
                <input
                  type="radio"
                  className="hidden"
                  checked={displayMode === "building"}
                  onChange={() => {
                    setDisplayMode("building");
                  }}
                />
                {displayMode === "building" ? (
                  <img src="/icons/radio_button_checked.svg" alt="checked" />
                ) : (
                  <img src="/icons/radio_button_unchecked.svg" alt="unchecked" />
                )}
                建物別
              </label>
              <button type="button" className="bg-black text-white px-4 py-2 rounded-md w-40" onClick={() => {}}>
                再マッチング
              </button>
              <button
                type="button"
                className="bg-black text-white px-4 py-2 rounded-md w-40"
                onClick={() => {
                  setMode("edit");
                }}
              >
                手動修正
              </button>
            </>
          )}
        </div>
        <div className="flex items-center justify-between">
          <p className="text-xl font-bold ml-8 mt-8">{workShiftGroup.name}</p>
        </div>
        {displayMode === "staff" ? <GroupByStaff workShiftGroup={workShiftGroup} mode={mode} /> : <GroupByBuilding />}
      </div>
    </div>
  );
};

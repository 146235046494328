import { Outlet } from "react-router-dom";
import { Sidebar } from "./components/Sidebar";

export const TwoColumnLayout = () => {
  return (
    <div className="flex w-screen h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-grow px-20 max-h-screen overflow-hidden">
        <Outlet />
      </main>
    </div>
  );
};

import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { staffShiftGroupSchema } from "./staffShiftGroup";
import { staffSchema } from "./staff";

export const staffShiftSchema = z.object({
  id: z.string().brand("staffShiftId"),
  staffShiftGroupId: staffShiftGroupSchema.shape.id,
  staffId: staffSchema.shape.id,
  shiftSymbol: z.string(), // 全日：Ａ、午前：Ｂ、深夜：Ｅ、未定：Ｇ、予約なし：―、勤務不可：×
  date: z.date(),
  startAt: z.date().nullable().optional(),
  endAt: z.date().nullable().optional(),
});

export type StaffShift = z.infer<typeof staffShiftSchema>;

export type StaffShiftInput = z.input<typeof staffShiftSchema>;

export const newStaffShift = (input: StaffShiftInput): StaffShift => staffShiftSchema.parse(input);

export const createStaffShift = (input: Omit<StaffShiftInput, "id">): StaffShift =>
  newStaffShift({ ...input, id: uuidv7() });

// "○", "X", "-", "A", "B", "E", "G"
export const symbolToTime = (symbol: string) => {
  if (symbol === "○") return { start: { hours: 0, minutes: 0 }, end: { hours: 24, minutes: 0 } };
  if (symbol === "X") return null;
  if (symbol === "-") return null;
  if (symbol === "A") return { start: { hours: 0, minutes: 0 }, end: { hours: 12, minutes: 0 } };
  if (symbol === "B") return { start: { hours: 12, minutes: 0 }, end: { hours: 18, minutes: 0 } };
  if (symbol === "E") return { start: { hours: 18, minutes: 0 }, end: { hours: 24, minutes: 0 } };
  if (symbol === "G") return { start: { hours: 0, minutes: 0 }, end: { hours: 0, minutes: 0 } };
  return null;
};

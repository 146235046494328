import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { groupBy } from "@senciamatch/frontend/libs/array";
import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { WorkShiftGroup } from "@senciamatch/shared/models/workShiftGroup";
import { v7 as uuidv7 } from "uuid";

type Props = {
  workShiftGroup: WorkShiftGroup;
  mode: "edit" | "view";
};

export const GroupByStaff = ({ workShiftGroup, mode }: Props) => {
  const getWorkShifts = trpc.getWorkShiftsByGroupId.useQuery({ workShiftGroupId: workShiftGroup.id });

  if (getWorkShifts.isLoading) return <LoadingData />;
  if (!getWorkShifts.data) return <ElementNotFound />;

  const workShifts = getWorkShifts.data;

  const workShiftsGroupedByStaffs = groupBy(workShifts, (workShift) => workShift.staffId);

  const lastDayOfMonth = new Date(
    workShiftGroup.month.getFullYear(),
    workShiftGroup.month.getMonth() + 1,
    0,
  ).getDate();

  return (
    <div className="h-full overflow-auto ml-8 mt-8">
      <table className="w-[2450px] border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="sticky top-0 left-0 bg-gray-100 p-2 border border-gray-100 z-20 min-w-[140px]">従業員名</th>
            {/* <th className="sticky top-0 bg-gray-100 p-2 border border-gray-100 z-10 min-w-[100px]">ステータス</th> */}
            {Array.from({ length: lastDayOfMonth }, (_, i) => {
              const dateStr = `${workShiftGroup.month}${i < 9 ? `0${i + 1}` : i + 1}`;
              const dayOfWeek = getJapaneseDayOfWeek(dateStr);
              const bgColor = dayOfWeek === "日" ? "bg-red-100" : dayOfWeek === "土" ? "bg-blue-100" : "bg-gray-100";
              return (
                <th key={uuidv7()} className={`sticky top-0 ${bgColor} p-2 border border-gray-100 z-10`}>
                  {i + 1}
                  <br />
                  {dayOfWeek}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.entries(workShiftsGroupedByStaffs).map(([staffId, workShifts]) => (
            <tr key={staffId}>
              <td className="sticky left-0 p-2 bg-white border border-gray-100 z-10">
                {staffsGroupedById[Number(staffId)]?.[0]?.name}
              </td>
              {Array.from({ length: lastDayOfMonth }, (_, i) => {
                const workShift = workShifts.find((workShift) => {
                  return workShift.startAt.getDay() === i + 1;
                });
                const buildingShift = buildingShiftsGroupedByBuildingId[workShift?.buildingId]?.[0];
                return (
                  <td key={uuidv7()} className="border border-gray-100 w-[270px] h-[80px] text-center">
                    {buildingShift?.name ?? "✕"}
                  </td>
                );
              })}
            </tr>
          ))}

          {/* {Object.entries(data).map(([staffId, workShifts]) => (
        <tr key={staffId}>
          <td className="sticky left-0 p-2 bg-white border border-gray-100 z-10">
            {groupByStaffs[Number(staffId)][0].name}
          </td>
          <td className="p-2 border border-gray-100">
            {workShifts.some((workShift) => workShift.status === "confirmed") ? "確定" : "未確定"}
          </td>
          {Array.from({ length: 31 }, (_, i) => {
            const workShift = pickWorkShift(workShifts, i + 1)[0];
            const shiftKey = `${staffId}-${i}`;
            const isSelected = selectedCShifts.includes(shiftKey);
            return (
              <td key={uuidv7()} className="border border-gray-100 w-[270px] h-[80px]">
                <div
                  className="w-[270px] h-[80px] relative flex items-center justify-center"
                  onClick={() => {
                    if (isSelected) {
                      setSelectedCShifts(selectedCShifts.filter((key) => key !== shiftKey));
                    } else {
                      setSelectedCShifts([...selectedCShifts, shiftKey]);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (isSelected) {
                        setSelectedCShifts(selectedCShifts.filter((key) => key !== shiftKey));
                      } else {
                        setSelectedCShifts([...selectedCShifts, shiftKey]);
                      }
                    }
                  }}
                >
                  {mode === "edit" ? (
                    <input type="checkbox" className="w-4 h-4 absolute top-2 left-2 z-1" checked={isSelected} />
                  ) : workShift?.status === "Confirmed" ? (
                    <img src="/icons/confirmed.svg" alt="確定" className="w-6 h-6 absolute top-2 left-2 z-1" />
                  ) : workShift?.status === "Unconfirmed" ? (
                    <img
                      src="/icons/unconfirmed.svg"
                      alt="未確定"
                      className="w-6 h-6 absolute top-2 left-2 z-1"
                    />
                  ) : null}

                  {workShift ? (
                    <p className="text-center text-sm w-[200px] mx-auto">
                      <span key={uuidv7()}>
                        {groupByBuildings[workShift.buildingId][0].name}
                        <br />
                        {workShift.hours}
                      </span>
                    </p>
                  ) : (
                    <p className="text-red-500">✕</p>
                  )}
                </div>
              </td>
            );
          })}
        </tr>
      ))} */}
        </tbody>
      </table>
    </div>
  );
};

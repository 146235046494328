import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { NewUserInput } from "@senciamatch/shared/models/user";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const ChangePasswordUserPage = () => {
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm<NewUserInput>();

  const generatePassword = () => {
    return Math.random().toString(36).slice(2, 15);
  };

  const getUser = trpc.getUser.useQuery({ id: id || "" });
  const changePassword = trpc.changePassword.useMutation();

  if (getUser.isLoading || !getUser.data) return <LoadingData />;
  if (!getUser.data) return <ElementNotFound />;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">パスワードを変更する</h2>
      <div className="flex-grow flex flex-col bg-white border border-base-100 px-8 max-w-[600px]">
        <form
          onSubmit={handleSubmit((data) => {
            if (!id || !data.password) return;
            changePassword.mutate({ id, password: data.password });
          })}
          className="flex flex-col gap-8 mt-8"
        >
          <div className="flex flex-row gap-2">
            <label htmlFor="password" className="text-sm text-gray-500">
              パスワード
            </label>
            <div className="flex items-center gap-2">
              <input
                id="password"
                type="text"
                className="border border-gray-300 rounded-md p-2 flex-grow"
                {...register("password")}
              />
              <button
                type="button"
                className="border border-primary-700 text-primary-700 px-4 py-2 rounded-md"
                onClick={() => setValue("password", generatePassword())}
              >
                自動生成
              </button>
            </div>
          </div>

          <button type="submit" className="w-full bg-[#00AF31] text-white p-2 rounded-md">
            編集
          </button>
        </form>
      </div>
    </div>
  );
};

import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { useNavigate } from "react-router-dom";
import { BuildingTable } from "./table";

export const BuildingsPage: React.FC = () => {
  const navigate = useNavigate();

  const getBuildings = trpc.getBuildings.useQuery();
  const getCompanies = trpc.getCompanies.useQuery();
  if (getBuildings.isLoading || getCompanies.isLoading) return <LoadingData />;
  if (!getBuildings.data || !getCompanies.data) return <ElementNotFound />;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">物件一覧</h2>
      <div className="overflow-x-auto py-10 bg-white border border-base-100 px-8 flex-grow flex flex-col">
        <div className="flex justify-between pb-8 flex-shrink-0">
          {/* <input type="text" className="w-1/2 border border-gray-200 rounded-md px-4 py-2" placeholder="検索" /> */}
          <button
            type="button"
            className="bg-primary-700 text-white px-4 py-2 rounded-md"
            onClick={() => navigate("/buildings/new")}
          >
            新規登録
          </button>
        </div>
        <BuildingTable buildings={getBuildings.data.buildings ?? []} companies={getCompanies.data.companies ?? []} />
      </div>
    </div>
  );
};

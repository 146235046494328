import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { WorkShift } from "@senciamatch/shared/models/workShift";

interface Props {
  workShift: WorkShift;
  setShowSearchStaffModal: (show: boolean) => void;
}

export const WorkShiftComponent = ({ workShift, setShowSearchStaffModal }: Props) => {
  const getBuilding = trpc.getBuilding.useQuery({ id: workShift.buildingId });
  const getStaff = trpc.getStaff.useQuery({ id: workShift.staffId });

  if (getBuilding.isLoading || getStaff.isLoading) return <LoadingData />;
  if (!getBuilding.data || !getBuilding.data.building || !getStaff.data || !getStaff.data.staff)
    return <ElementNotFound />;

  const building = getBuilding.data.building;
  const staff = getStaff.data.staff;

  return (
    <div key={workShift.id} className="py-4">
      <p className="bg-[#EBEBEB] px-4 rounded-t-md h-[75px] leading-[75px] text-[24px] font-bold">
        {workShift.startAt.toLocaleDateString()}（{getJapaneseDayOfWeek(workShift.startAt.toISOString())}）
        {building.name} / {workShift.startAt.toLocaleTimeString()} - {workShift.endAt.toLocaleTimeString()}
      </p>
      <div className="bg-white rounded-b-md p-8">
        <p className="h-[75px] leading-[75px] text-[24px] font-bold">現状の登録状況</p>

        <div className="flex flex-raw border border-[#C2C2C2] h-[55px]">
          <p className="border-r border-[#C2C2C2] px-4 leading-[55px] text-[16px] bg-[#F8F8F8] w-80">従業員名</p>
          <p className="px-4 leading-[55px] text-[16px]">{staff.name}</p>
        </div>

        <p className="h-[75px] leading-[75px] text-[24px] font-bold">修正後のシフト</p>

        <button
          type="button"
          className="bg-[#00AF31] text-white px-4 py-2 rounded-md w-80 h-14 mb-8"
          onClick={() => setShowSearchStaffModal(true)}
        >
          従業員を検索
        </button>

        <div className="flex flex-raw border border-[#C2C2C2] h-[55px]">
          <p className="border-r border-[#C2C2C2] px-4 leading-[55px] text-[16px] bg-[#F8F8F8] w-80">従業員名</p>
          <p className="px-4 leading-[55px] text-[16px]">{staff.name}</p>
        </div>
      </div>
    </div>
  );
};

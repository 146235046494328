import { fileParser } from "@senciamatch/frontend/libs/csv";
import { type StaffStatus, staffParser } from "@senciamatch/frontend/libs/csvParser/staffs";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { Staff } from "@senciamatch/shared/models/staff";
import { useCallback, useState } from "react";
import { StaffTable } from "./table";

export const NewStaffPage: React.FC = () => {
  const [staffs, setStaffs] = useState<Staff[]>([]);
  const [statuses, setStatuses] = useState<StaffStatus>({});
  const [showAll, setShowAll] = useState(false);

  const createStaffsMutation = trpc.createStaffs.useMutation();

  const utils = trpc.useContext();
  const getStaffs = trpc.getStaffs.useQuery();
  const existingStaffs = getStaffs.data?.staffs ?? [];

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (!file) return;
      const data = await fileParser(file);
      if (!Array.isArray(data)) return;

      await utils.getStaffs.invalidate();

      const parsedStaffs = await staffParser.parse(data, existingStaffs);

      setStaffs(parsedStaffs.staffs);
      setStatuses(parsedStaffs.status);
    },
    [existingStaffs, utils.getStaffs.invalidate],
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleSubmit = useCallback(async () => {
    const params = {
      staffs: staffs.filter((staff) => statuses[staff.id] !== "persisted"),
    };

    const chunkSize = 20;
    const length = Math.max(params.staffs.length);
    for (let i = 0; i < length; i += chunkSize) {
      const chunk = {
        staffs: params.staffs.slice(i, i + chunkSize),
      };

      await createStaffsMutation.mutateAsync(chunk);
    }

    alert("登録が完了しました");
  }, [staffs, statuses, createStaffsMutation]);

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">従業員の登録</h2>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="rounded-md border-dashed border-2 border-gray-200 bg-white p-4 min-h-40 flex flex-col justify-center items-center mb-10"
      >
        <p className="text-center">ファイルをここにドラッグ＆ドロップしてください</p>
      </div>

      {staffs.length > 0 && (
        <div className="overflow-x-auto py-10 bg-white border border-base-100 px-8 flex-grow flex flex-col">
          <label className="flex items-center gap-2 mb-4">
            <input type="checkbox" className="checkbox" checked={showAll} onChange={() => setShowAll(!showAll)} />
            <span className="label-text">追加・更新分以外も表示</span>
          </label>
          <StaffTable staffs={showAll ? staffs : staffs.filter((staff) => statuses[staff.id] !== "persisted")} />
          <button type="button" className="bg-primary-700 text-white px-4 py-2 rounded-md mt-4" onClick={handleSubmit}>
            新規登録
          </button>
        </div>
      )}
    </div>
  );
};

export const getJapaneseDayOfWeek = (dateString: string) => {
  const year = Number(dateString.slice(0, 4));
  const month = Number(dateString.slice(4, 6)) - 1;
  const day = Number(dateString.slice(6, 8));

  const date = new Date(year, month, day);
  const dayOfWeek = date.getDay();

  const japaneseDays = ["日", "月", "火", "水", "木", "金", "土"];
  return japaneseDays[dayOfWeek];
};

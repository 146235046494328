import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { StaffTable } from "./table";

interface Props {
  show: boolean;
  handleClose: () => void;
}

export const SearchStaffModal: React.FC<Props> = ({ show, handleClose }) => {
  const getStaffs = trpc.getStaffs.useQuery();

  if (!show) return null;

  if (getStaffs.isLoading) return <LoadingData />;
  if (!getStaffs.data) return <ElementNotFound />;

  const staffs = getStaffs.data.staffs;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="w-full h-full relative">
        <div className="bg-white rounded-t-lg shadow-lg w-[90%] h-[90%] bottom-0 left-[5%] absolute">
          <div className="bg-[#EBEBEB] rounded-t-lg flex justify-between items-center p-2 relative h-[120px]">
            <h2 className="text-center text-[24px] font-bold mx-auto">
              2024年6月1日（土）ナーベル / 8:00-20:00のシフト変更者を探す
            </h2>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 absolute font-bold text-[30px] top-[45px] right-[45px]"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
          <div className="mt-4 p-8">
            <p className="h-[75px] leading-[75px] text-[24px] font-bold">従業員名で絞り込む</p>
            <input
              type="text"
              className="h-[75px] leading-[75px] text-[24px] font-bold border border-[#C2C2C2] w-full rounded-md"
            />
            <p className="h-[75px] leading-[75px] text-[24px] font-bold">フィルタリング</p>

            <div className="flex flex-raw gap-8">
              <label>
                <input type="checkbox" className="w-4 h-4" />
                <span className="ml-2">過去経験がある従業員</span>
              </label>

              <label>
                <input type="checkbox" className="w-4 h-4" />
                <span className="ml-2">空いている従業員</span>
              </label>

              <label>
                <input type="checkbox" className="w-4 h-4" />
                <span className="ml-2">スーツ可</span>
              </label>
            </div>

            <p className="h-[75px] leading-[75px] text-[24px] font-bold">従業員</p>
          </div>
          <StaffTable staffs={staffs ?? []} />
        </div>
      </div>
    </div>
  );
};

import type { Staff } from "@senciamatch/shared/models/staff";

interface Props {
  staffs: Staff[];
}

export const StaffTable: React.FC<Props> = ({ staffs }) => {
  return (
    <div className="overflow-y-auto flex-grow">
      <table className="min-w-full">
        <thead className="sticky top-0 bg-gray-100">
          <tr className="bg-gray-100">
            <th className="p-2 text-left border border-gray-200">スタッフ番号</th>
            <th className="p-2 text-left border border-gray-200">氏名（漢字）</th>
            <th className="p-2 text-left border border-gray-200">氏名（カナ）</th>
            <th className="p-2 text-left border border-gray-200">郵便番号</th>
            <th className="p-2 text-left border border-gray-200">住所</th>
          </tr>
        </thead>
        <tbody>
          {staffs.map((staff) => (
            <tr key={staff.id}>
              <td className="p-2 text-left border border-gray-200">{staff.id}</td>
              <td className="p-2 text-left border border-gray-200">{staff.name}</td>
              <td className="p-2 text-left border border-gray-200">{staff.nameKana}</td>
              <td className="p-2 text-left border border-gray-200">{staff.postalCode}</td>
              <td className="p-2 text-left border border-gray-200">{staff.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

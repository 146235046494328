import { createBuildingShift } from "@senciamatch/shared/models/buildingShift";
import { createBuildingShiftGroup } from "@senciamatch/shared/models/buildingShiftGroup";
import { createBuildingShiftTable } from "@senciamatch/shared/models/buildingShiftTable";
import * as XLSX from "xlsx";

interface CellData {
  [key: string]: {
    value: string | number;
    comment?: string;
  };
}

const nextCell = (currentCell: string) => {
  const match = currentCell.match(/^([A-Z]+)(\d+)$/);
  console.log("match", currentCell, match);

  if (!match) return null;

  let column = match[1];
  const row = match[2];
  console.log(column);

  let i = column.length - 1;
  while (i >= 0) {
    if (column[i] === "Z") {
      column = `${column.substring(0, i)}A${column.substring(i + 1)}`;
      if (i === 0) {
        column = `A${column}`;
      }
      i--;
    } else {
      column = column.substring(0, i) + String.fromCharCode(column.charCodeAt(i) + 1) + column.substring(i + 1);
      break;
    }
  }

  return `${column}${row}`;
};

const excelSerialDateToJSDate = (serial: number): Date => {
  // Excelの日付は1900年1月1日を基準としてカウントされている
  const excelEpoch = new Date(1899, 11, 30); // 1899年12月30日が基準
  const daysOffset = serial; // シリアル値分の日数を追加
  const jsDate = new Date(excelEpoch.getTime() + daysOffset * 24 * 60 * 60 * 1000);
  return jsDate;
};

const getLastDayOfMonth = (cellData: CellData) => {
  const dateCell = cellData.B6;
  const date = excelSerialDateToJSDate(Number(dateCell.value));
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  return lastDayOfMonth;
};

/*
    受注： (00001166) グランスイート虎ノ門
    現場： (00001334) グランスイート虎ノ門

    集合： 
  最寄駅： 東京メトロ日比谷線 虎ノ門ヒルズ駅より 徒歩1分
    期間： 2024/10/01(火)～2024/10/10(木)  月火水木金
    作業： 【夜勤/仮眠なし】Aエリア-8H
    時間： 21:00～32:00  残業無し
    性別： 指定なし

*/
const getBuildingIdAndName = (comment: string) => {
  const regex = /現場： \((\d+)\) (.+)/;
  const match = comment.match(regex);
  if (match) {
    const buildingId = Number.parseInt(match[1], 10).toString();
    return [buildingId, match[2]];
  }
  return [null, null];
};

const excelSerialDateToYYYYMM = (serial: number): string => {
  const date = excelSerialDateToJSDate(serial);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月を2桁にフォーマット
  return `${year}${month}`;
};

const buildBuildingShiftTable = (cellData: CellData) => {
  const rows = [];

  const BUILDING_COL = "B";
  const BUILDING_RAW_START = 12;
  const lastDayOfMonth = getLastDayOfMonth(cellData);

  let raw = BUILDING_RAW_START;

  let building = cellData[`${BUILDING_COL}${raw}`];

  const dateCell = excelSerialDateToYYYYMM(Number(cellData.B6.value));
  const year = Number(dateCell.substring(0, 4));
  const month = Number(dateCell.substring(4, 6));

  const buildingShiftGroup = createBuildingShiftGroup({
    month: dateCell,
  });

  // 行のループ
  while (building != null) {
    if (!building.comment) break;
    const [buildingId, buildingName] = getBuildingIdAndName(building.comment);
    const shiftTime = cellData[`Q${raw}`].value as string;
    const [startHour, startMinuet, endHour, endMinuet] = shiftTime.split(/-|:/);

    const cells = [];
    let shiftCell = `X${raw}`;

    for (let i = 1; i <= lastDayOfMonth; i++) {
      if (cellData[shiftCell].value !== 1) {
        cells.push(null);
        shiftCell = nextCell(shiftCell) ?? "";
        continue;
      }
      const startAt = new Date(year, month, i, Number(startHour), Number(startMinuet));
      const endAt = new Date(year, month, i, Number(endHour), Number(endMinuet));

      if (!buildingId) continue;

      const buildingShift = createBuildingShift({
        buildingShiftGroupId: buildingShiftGroup.id,
        buildingId,
        startAt,
        endAt,
      });

      cells.push(buildingShift);
      shiftCell = nextCell(shiftCell) ?? "";
    }

    raw++;
    rows.push({
      label: [buildingId, buildingName, shiftTime].join(" / "),
      cells,
    });
    building = cellData[`${BUILDING_COL}${raw}`];
  }

  return createBuildingShiftTable({
    buildingShiftGroup,
    rows,
  });
};

const parser = (input: Uint8Array) => {
  // InputからExcelデータを読み込む
  const workbook = XLSX.read(input, { type: "buffer", cellStyles: true });

  // 最初のシートを取得
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];

  // セルの値とコメントを格納するオブジェクト
  const cellData: CellData = {};

  // 各セルをループ
  for (const cellAddress in worksheet) {
    if (cellAddress[0] === "!") continue; // メタデータをスキップ

    const cell = worksheet[cellAddress];

    cellData[cellAddress] = {
      value: cell.v, // セルの値
      comment: cell.c ? cell.c[0].t : undefined, // コメントがあれば取得
    };
  }

  return buildBuildingShiftTable(cellData);
};

export const excelParserBuildingShiftTable = {
  parser,
};

import { identity } from "@senciamatch/frontend/libs/func";

export const filterMap = <T, U>(ts: T[], f: (t: T) => U | null | undefined): U[] => ts.flatMap((t) => f(t) ?? []);

export const arrayToRecordBy = <K extends keyof never, T, V>(
  as: T[],
  getIndex: (t: T) => K,
  getValue: (t: T) => V,
): Record<K, V> =>
  // パフォーマンスを考慮してスプレッド構文は使わない
  as.reduce(
    (acc, t) => {
      acc[getIndex(t)] = getValue(t);
      return acc;
    },
    {} as Record<K, V>,
  );

export const arrayInArrayToRecordBy = <K extends keyof never, T, V>(
  as: T[],
  getIndexes: (t: T) => K[],
  getValue: (t: T) => V,
): Record<K, V> =>
  as.reduce(
    (acc, t) => {
      for (const key of getIndexes(t)) acc[key] = getValue(t);
      return acc;
    },
    {} as Record<K, V>,
  );

export const arrayToRecord = <K extends keyof never, T>(as: T[], getIndex: (t: T) => K): Record<K, T> =>
  arrayToRecordBy(as, getIndex, identity);

export const mapForRecord = <K extends keyof never, T>(keys: K[], getValue: (k: K) => T): Record<K, T> =>
  arrayToRecordBy(keys, identity, getValue);

export const groupBy = <T, U extends PropertyKey>(as: T[], getKey: (a: T) => U): { [key in U]: T[] } =>
  // パフォーマンスを考慮してスプレッド構文は使わない
  as.reduce<{ [key in U]: T[] }>(
    (acc, a) => {
      const key: U = getKey(a);
      acc[key] ??= [];
      acc[key].push(a);
      return acc;
    },
    {} as { [key in U]: T[] },
  );

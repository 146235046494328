import { removeToken } from "@senciamatch/frontend/libs/auth";
import { useSidebarMenuStore } from "@senciamatch/frontend/stores/useSidebarMenuStore";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarMenu } from "./SidebarMenu";

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isOpen = useSidebarMenuStore((state) => state.sidebarIsOpen);
  const [showSidebar, setShowSidebar] = useState(isOpen);
  const currentSidebar = (target: string) => {
    if (target === "matchings") {
      return location.pathname === "/matchings" || location.pathname.includes("matchings?");
    }
    return location.pathname.includes(target);
  };

  const setIsOpen = useSidebarMenuStore((state) => state.setIsOpen);

  const handleLogout = async () => {
    removeToken();
    navigate("/login");
  };

  if (!showSidebar) {
    return (
      <button
        type="button"
        className="w-10 h-10 fixed top-4 left-4 border border-gray-300 rounded-full shadow-sm bg-slate-50"
        onClick={() => {
          setShowSidebar(true);
          setIsOpen(true);
        }}
      >
        <img src="/icons/double-arrow-right.svg" alt=">>" className="w-10 h-10 pt-1 pb-2 pl-[7px] pr-1 fill-black" />
      </button>
    );
  }

  return (
    <div className="w-72 h-full bg-white flex-shrink-0 py-4">
      <div className="flex flex-row justify-between items-center px-4">
        <Link to="/">
          <h1 className="text-2xl inline-block" style={{ fontFamily: "Outfit", fontWeight: "100" }}>
            Sencia Match
          </h1>
        </Link>
        <button
          className="i-hugeicons-arrow-left-double w-10 h-10 cursor-pointer bg-black-100"
          onClick={() => {
            setShowSidebar(false);
            setIsOpen(false);
          }}
          type="button"
        >
          <img src="/icons/double-arrow-left.svg" alt=">>" className="w-10 h-10 mt-1 p-2 fill-black" />
        </button>
      </div>

      <p className="text-gray-500 mt-10 mb-2 px-4 text-xs">マスターデータ</p>
      <SidebarMenu label="従業員一覧" icon="staff" isCurrent={currentSidebar("staffs")} link="/staffs" />
      <SidebarMenu label="物件一覧" icon="building" isCurrent={currentSidebar("buildings")} link="/buildings" />
      <SidebarMenu
        label="マスターデータを追加"
        icon="plus"
        isCurrent={currentSidebar("add-master")}
        link="/add-master"
      />

      <p className="text-gray-500 mt-10 mb-2 px-4 text-xs">マッチング</p>
      <SidebarMenu
        label="新規マッチング"
        icon="matching"
        isCurrent={currentSidebar("matchings/new")}
        link="/matchings/new"
      />
      <SidebarMenu label="マッチング一覧" icon="history" isCurrent={currentSidebar("matchings")} link="/matchings" />

      <p className="text-gray-500 mt-10 mb-2 px-4 text-xs">その他設定</p>
      <SidebarMenu label="ユーザー一覧" icon="user" isCurrent={currentSidebar("users")} link="/users" />
      <SidebarMenu label="ログアウト" icon="logout" isCurrent={currentSidebar("logout")} onClick={handleLogout} />
    </div>
  );
};

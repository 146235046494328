import { createStaffShift } from "@senciamatch/shared/models/staffShift";
import { createStaffShiftGroup } from "@senciamatch/shared/models/staffShiftGroup";
import { type StaffShiftTable, createStaffShiftTable } from "@senciamatch/shared/models/staffShiftTable";

// 全日：Ａ、午前：Ｂ、深夜：Ｅ、未定：Ｇ、予約なし：―、勤務不可：×
const VALID_SHIFT = ["○", "X", "-", "A", "B", "E", "G"];

const parseMonth = (data: string[][]) => {
  const firstRow = data[0];
  console.log("firstRow", firstRow[16]);
  // firstRow[16]
  // 予約情報－24/08/01（木）
  const dateString = firstRow[16].split(/－|−/)[1]; // "24/08/01（木）"
  console.log("dateString", dateString);
  const [year, month] = dateString.split("/"); // ["24", "08", "01（木）"]
  console.log("year", year);
  console.log("month", month);

  return {
    year: Number(year) + 2000,
    month: Number(month),
  };
};

const getLastDayOfMonth = ({ year, month }: { year: number; month: number }) => {
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
  return lastDayOfMonth;
};

const parse = async (data: string[][]): Promise<StaffShiftTable> => {
  const { year, month } = parseMonth(data);
  const staffShiftGroup = createStaffShiftGroup({
    month: `${year}${month < 10 ? `0${month}` : month}`,
  });
  const rows = [];

  for (const row of data.slice(1)) {
    const staffId = row[0];
    if (Number.isNaN(Number(staffId)) || staffId === "") continue;

    const START_COL = 16;
    const END_COL = START_COL + getLastDayOfMonth({ year, month });

    const cells = row.slice(START_COL, END_COL).map((r, index) => {
      const symbol = r.replace("+", "");
      const formattedMonth = month.toString().padStart(2, "0");
      const formattedDay = (index + 1).toString().padStart(2, "0");
      const dateString = `${year}-${formattedMonth}-${formattedDay}`;
      const date = new Date(dateString);

      if (Number.isNaN(date.getTime())) {
        throw new Error(`Invalid date generated: ${dateString}`);
      }

      return createStaffShift({
        staffShiftGroupId: staffShiftGroup.id,
        staffId,
        shiftSymbol: symbol,
        date,
      });
    });

    const staffShiftRow = {
      label: [staffId, row[1]].join(" / "),
      cells,
    };

    rows.push(staffShiftRow);
  }

  return createStaffShiftTable({
    staffShiftGroup,
    rows,
  });
};

export const staffShiftTableParser = {
  parse,
  VALID_SHIFT,
};

import { z } from "zod";
import { companySchema } from "./company";

export const buildingSchema = z.object({
  id: z.string().brand("buildingId"),
  name: z.string(),
  nameKana: z.string(),
  postalCode: z.string(),
  address: z.string(),
  companyId: companySchema.shape.id,
  isSuitsNeeded: z.boolean().default(false),
  mustCleaning: z.boolean().default(false),
  menOnly: z.boolean().default(false),
  womenOnly: z.boolean().default(false),
});

export type Building = z.infer<typeof buildingSchema>;

export type BuildingInput = z.input<typeof buildingSchema>;

export const newBuilding = (input: BuildingInput): Building => buildingSchema.parse(input);

export const createBuilding = (input: BuildingInput): Building => newBuilding(input);

import { Link } from "react-router-dom";

export const AddMaster = () => {
  return (
    <div className="flex flex-col flex-grow">
      <h2 className="text-2xl font-bold py-10">マスターデータを追加</h2>
      <div className="flex flex-col gap-2 mt-20">
        <p className="text-xl font-bold text-center my-4">追加・更新するマスタを選択してください</p>
        <div className="flex flex-row gap-8 justify-center">
          <Link to="/staffs/new">
            <div className="bg-primary-600 text-white p-2 rounded-md w-72 h-80 flex justify-center items-center">
              <p className="text-center font-bold text-xl">従業員マスタを更新</p>
            </div>
          </Link>
          <Link to="/buildings/new">
            <div className="bg-base-900 text-white p-2 rounded-md w-72 h-80 flex justify-center items-center">
              <p className="text-center font-bold text-xl">物件マスタを更新</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PublicLayout } from "./layouts/PublicLayout";
import { TwoColumnLayout } from "./layouts/TwoColumnLayout";
import { AddMaster } from "./pages/AddMaster";
import { BuildingsPage } from "./pages/Buildings";
import { NewBuildingPage } from "./pages/Buildings/New";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { MatchingsPage } from "./pages/Matchings";
import { NewMatchingPage } from "./pages/Matchings/New";
import { MatchingShowPage } from "./pages/Matchings/Show";
import { EditWorkShiftsPage } from "./pages/Matchings/WorkShifts/Edit";
import { StaffsPage } from "./pages/Staffs";
import { NewStaffPage } from "./pages/Staffs/New";
import { UsersPage } from "./pages/Users";
import { EditUserPage } from "./pages/Users/Edit";
import { NewUserPage } from "./pages/Users/New";
import { ChangePasswordUserPage } from "./pages/Users/ChangePassword";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/" element={<TwoColumnLayout />}>
          <Route index element={<Home />} />
          <Route path="/matchings" element={<MatchingsPage />} />
          <Route path="/matchings/new" element={<NewMatchingPage />} />
          <Route path="/matchings/work-shifts/edit" element={<EditWorkShiftsPage />} />
          <Route path="/staffs" element={<StaffsPage />} />
          <Route path="/staffs/new" element={<NewStaffPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/users/new" element={<NewUserPage />} />
          <Route path="/users/:id/edit" element={<EditUserPage />} />
          <Route path="/users/:id/change-password" element={<ChangePasswordUserPage />} />
          <Route path="/buildings" element={<BuildingsPage />} />
          <Route path="/buildings/new" element={<NewBuildingPage />} />
          <Route path="/add-master" element={<AddMaster />} />
          <Route path="/matchings/:id" element={<MatchingShowPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import type { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  icon?: string;
  isCurrent: boolean;
  link?: string;
  onClick?: () => void;
}

const Base: FC<Props> = ({ label, icon, isCurrent, onClick }) => {
  return (
    <div
      className={`cursor-pointer pl-4 flex flex-row items-center gap-2 ${isCurrent ? "text-primary-600" : "text-base-600"}`}
      onClick={() => onClick?.()}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick?.();
        }
      }}
    >
      {icon && (
        <img
          src={isCurrent ? `/icons/${icon}-on.svg` : `/icons/${icon}.svg`}
          alt={label}
          width={16}
          height={16}
          className={`w-4 h-4 mx-4 ${isCurrent ? "fill-primary-500" : "fill-base-600"}`}
        />
      )}
      <span className="text-sm">{label}</span>
    </div>
  );
};

export const SidebarMenu: FC<Props> = (props) => {
  const { isCurrent, link } = props;
  if (link) {
    return (
      <Link
        to={link}
        className={`my-4 w-[260px] rounded-r-full h-10 py-[10px] block ${isCurrent ? "bg-primary-50" : ""}`}
      >
        <Base {...props} />
      </Link>
    );
  }

  return <Base {...props} />;
};

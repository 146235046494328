import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { NewUserInput } from "@senciamatch/shared/models/user";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const EditUserPage = () => {
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm<NewUserInput>();

  const getUser = trpc.getUser.useQuery({ id: id || "" });
  const updateUser = trpc.updateUser.useMutation();

  useEffect(() => {
    if (getUser.data?.user) {
      setValue("email", getUser.data.user?.email);
      setValue("firstName", getUser.data.user?.firstName);
      setValue("lastName", getUser.data.user?.lastName);
    }
  }, [getUser.data, setValue]);

  if (!id) return <ElementNotFound />;

  if (getUser.isLoading || !getUser.data) return <LoadingData />;
  if (!getUser.data) return <ElementNotFound />;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">ユーザー変更</h2>
      <div className="flex-grow flex flex-col bg-white border border-base-100 px-8 max-w-[600px]">
        <form
          onSubmit={handleSubmit((data) => {
            if (!id) return;
            updateUser.mutate({ id: id || "", data });
          })}
          className="flex flex-col gap-8 mt-8"
        >
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-2 flex-grow">
              <label htmlFor="lastName" className="text-sm text-gray-500">
                名字
              </label>
              <input
                id="lastName"
                type="text"
                className="border border-gray-300 rounded-md p-2"
                {...register("lastName")}
              />
            </div>

            <div className="flex flex-col gap-2 flex-grow">
              <label htmlFor="firstName" className="text-sm text-gray-500">
                名前
              </label>
              <input
                id="firstName"
                type="text"
                className="border border-gray-300 rounded-md p-2"
                {...register("firstName")}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="email" className="text-sm text-gray-500">
              メールアドレス
            </label>
            <input id="email" type="email" className="border border-gray-300 rounded-md p-2" {...register("email")} />
          </div>

          <button type="submit" className="w-full bg-[#00AF31] text-white p-2 rounded-md">
            編集
          </button>
        </form>
      </div>
    </div>
  );
};

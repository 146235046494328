import { groupBy } from "@senciamatch/frontend/libs/array";
import type { Building } from "@senciamatch/shared/models/building";
import type { Company } from "@senciamatch/shared/models/company";
import { v7 as uuidv7 } from "uuid";

interface Props {
  buildings: Building[];
  companies: Company[];
}

export const BuildingTable: React.FC<Props> = ({ buildings, companies }) => {
  const groupedCompanies = groupBy(companies, (company) => company.id);

  return (
    <div className="overflow-y-auto flex-grow border-y border-gray-200">
      <table className="min-w-full">
        <thead className="sticky top-0 bg-gray-100">
          <tr className="bg-gray-100">
            <th className="p-2 text-left border border-gray-200 w-1/12">物件番号</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">物件名</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">住所</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">会社</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">その他情報</th>
          </tr>
        </thead>
        <tbody className="border-y border-gray-200">
          {buildings.map((building) => {
            const company = groupedCompanies[building.companyId]?.[0];
            return (
              <tr key={uuidv7()}>
                <td className="p-2 text-left border border-gray-200">{building.id}</td>
                <td className="p-2 text-left border border-gray-200">
                  {building.name}
                  <br />
                  {building.nameKana}
                </td>
                <td className="p-2 text-left border border-gray-200">
                  {building.postalCode}
                  <br />
                  {building.address}
                </td>
                <td className="p-2 text-left border border-gray-200">{company?.name}</td>
                <td className="p-2 text-left border border-gray-200">
                  スーツ着用: {building.isSuitsNeeded ? "必要" : "不要"}
                  <br />
                  清掃: {building.mustCleaning ? "必要" : "不要"}
                  <br />
                  男性専用: {building.menOnly ? "専用" : "共用"}
                  <br />
                  女性専用: {building.womenOnly ? "専用" : "共用"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

import type React from "react";
import { useState } from "react";
import { MatchingBuildings } from "./components/Buildings";
import { MatchingStaffs } from "./components/Staffs";

export const NewMatchingPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<"buildings" | "staffs">("buildings");
  const [buildingShiftGroupId, setBuildingShiftGroupId] = useState<string | null>(null);

  if (currentPage === "buildings")
    return <MatchingBuildings setCurrentPage={setCurrentPage} setBuildingShiftGroupId={setBuildingShiftGroupId} />;
  return <MatchingStaffs buildingShiftGroupId={buildingShiftGroupId} />;
};

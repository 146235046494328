import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="flex flex-col flex-grow">
      <h2 className="text-2xl font-bold pt-10">メニュー</h2>
      <div className="flex flex-col gap-2">
        <p className="text-xl font-bold text-left mt-20 mb-2">マスターデータの確認・更新</p>
        <div className="flex flex-row gap-8">
          <Link to="/staffs">
            <div className="bg-base-100 text-base-900 p-2 rounded-md w-60 h-40 flex justify-center items-center">
              <p className="text-center text-lg">従業員マスタ一覧</p>
            </div>
          </Link>
          <Link to="/buildings">
            <div className="bg-base-100 text-base-900 p-2 rounded-md w-60 h-40 flex justify-center items-center">
              <p className="text-center text-lg">物件マスタ一覧</p>
            </div>
          </Link>
          <Link to="/add-master">
            <div className="bg-base-100 text-base-900 p-2 rounded-md w-60 h-40 flex justify-center items-center">
              <p className="text-center text-lg">マスターデータを追加</p>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-xl font-bold text-left mt-20 mb-2">マッチング</p>
        <div className="flex flex-row gap-8">
          <Link to="/matchings/new">
            <div className="bg-base-100 text-base-900 p-2 rounded-md w-60 h-40 flex justify-center items-center">
              <p className="text-center text-lg">新規マッチング</p>
            </div>
          </Link>
          <Link to="/matchings">
            <div className="bg-base-100 text-base-900 p-2 rounded-md w-60 h-40 flex justify-center items-center">
              <p className="text-center text-lg">マッチング履歴</p>
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-xl font-bold text-left mt-20 mb-2">その他設定</p>
        <div className="flex flex-row gap-8">
          <Link to="/users">
            <div className="bg-base-100 text-base-900 p-2 rounded-md w-60 h-40 flex justify-center items-center">
              <p className="text-center text-lg">ユーザー管理</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

import { Outlet } from "react-router-dom";

export const PublicLayout = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-100">
      <div className="min-h-96 w-[480px] border m-auto p-4 bg-white">
        <h1 className="text-2xl text-center border-b pb-4" style={{ fontFamily: "Outfit", fontWeight: "100" }}>
          Sencia Match
        </h1>
        <div className="mt-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

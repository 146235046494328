import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchStaffModal } from "./components/SearchStaffModal";
import { WorkShiftComponent } from "./components/WorkShift";

export const EditWorkShiftsPage = () => {
  const [showSearchStaffModal, setShowSearchStaffModal] = useState(false);
  const [searchParams] = useSearchParams({ ids: [] });
  let ids = searchParams.get("ids")?.split(",");
  ids = ["0192baf7-e8f2-7660-92ba-85dba59a5246", "0192baf7-e90d-7660-92ba-e3e94a8a8454"];

  const getWorkShifts = trpc.getWorkShifts.useQuery({ ids });

  if (getWorkShifts.isLoading) return <LoadingData />;
  if (!getWorkShifts.data) return <ElementNotFound />;

  const workShifts = getWorkShifts.data;

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">選択項目のシフト修正（{workShifts.length}件）</h2>

      {workShifts.map((workShift) => (
        <WorkShiftComponent
          key={workShift.id}
          workShift={workShift}
          setShowSearchStaffModal={setShowSearchStaffModal}
        />
      ))}
      <SearchStaffModal show={showSearchStaffModal} handleClose={() => setShowSearchStaffModal(false)} />
    </div>
  );
};
